/* General Styles */
body,
html,
#root {
  height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif; /* Updated font for a more modern look */
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f8f9fa; /* Lighter background color */
  padding: 20px; /* Added padding for better spacing */
}

.centered-box {
  background-color: white;
  padding: 30px; /* Increased padding for better spacing */
  border-radius: 10px; /* Slightly larger border radius for smoother corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0; /* Added margin to ensure it's not off the screen */
}

.map-container {
  width: 100%;
   flex-grow: 1; 
  height: 70vh; /* Adjusted height to fit better with other elements */
  margin-top: 20px; /* Added margin to separate from the top elements */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.bottomContainer {
 width: 100%;
}


/* Layer List Styles */
.layer-list {
  width: 100%;
  margin-top: 10px;
}

.layer-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.layer-color-box {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 3px;
  border: 1px solid #000;
}

.layer-item button {
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  margin-left: auto; /* Align the button to the right */
}

.layer-item button:hover {
  background-color: #c9302c;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Input Styles */
input[type="text"],
input[type="color"],
input[type="range"] {
  flex: 1;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="color"]:focus,
input[type="range"]:focus {
  border-color: #3d9de6;
}

/* Button Styles */
.button,
.howtoButton {
  background-color: #3d9de6;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  margin: 10px; /* Ensure consistent spacing */
}

.button:hover,
.howtoButton:hover {
  background-color: #218ee1;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Typography */
.mainName {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

h3 {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
}

/* Controls Container */
.controlsContainer {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping on larger screens */
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 20px;
  border: 2px solid #103c5e;
  border-radius: 5px;
  padding: 5px;
}

label {
  margin-top: 10px;
  font-size: 1rem;
  color: #666;
}

.opacityContainer {
  display: flex;
  flex-direction: column;
  border-left: 2px solid #103c5e;
  border-right: 2px solid #103c5e;
  padding-left: 5px;
  align-items: center; /* Center items vertically */
}

.colorPickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items vertically */
}

/* Responsive Layout */
@media (max-width: 767px) {
  .controlsContainer {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    flex-direction: column;
    align-items: center;
  }

  .map-container {
    height: 50vh; /* Adjust map height on smaller screens */
  }

  .opacityContainer {
    display: flex;
    flex-direction: column;
    border-left: none;
    border-right: none;
    border-top: 2px solid #103c5e;
    border-bottom: 2px solid #103c5e;
    width: 100%;
    padding: 10px 0;
    align-items: center; /* Center items vertically */
  }

  .colorPickerContainer {
    width: 100%;
    padding: 10px 0;
    align-items: center; /* Center items vertically */
    height: fit-content;
  }

  .colorPickerContainer input[type="color"] {
    width: 100px;
    height: 50px;
  }

  .controlsContainer input[type="text"] {
    width: calc(100% - 20px); /* Ensure it fits within the container */
    margin: 10px;
  }

  .button {
    width: calc(100% - 20px); /* Ensure it fits within the container */
    margin: 10px;
  }
}

#style1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100px;
  height: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}
#style1::-webkit-color-swatch {
  border-radius: 10px;
  border: none;
}
#style1::-moz-color-swatch {
  border-radius: 10px;
  border: none;
}


#style2 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}
#style2::-webkit-color-swatch {
  border-radius: 10px;
  border: none;
}
#style2::-moz-color-swatch {
  border-radius: 10px;
  border: none;
}


